import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetHrTypes} from "../../../di/types";
import {DashboardService} from "../domain";
import {Dashboard, DashboardApiResponse, DashboardChart, DatasetChart, RequestsStats} from "../../../models/dashboard";
import {DateRange} from "../../../models/dateRange";
import {uniq, groupBy, map, each, sumBy} from 'lodash-es';

@injectable()
export class GetDashboardUseCase {
  constructor(
    @inject(cabinetHrTypes.DashboardService) private readonly dashboardService: DashboardService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getDashboardByDateRange(companyId: string, dateRange: DateRange): Promise<Dashboard> {
    const response = await this.dashboardService.fetchDashboardByDateRange(companyId, dateRange);
    if (response.isOk()) {
      const dashboard = response.value as Dashboard;
      dashboard.lineChartRequestsDataset = this.prepareLineRequestsDataset(response.value);
      dashboard.pieChartRequestsDataset = this.preparePieRequestsDataset(response.value);
      dashboard.genderChartDataset = this.prepareGenderDataset(response.value);
      return  dashboard;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getSessionsByDateRange(companyId: string, dateRange: DateRange, page: number): Promise<any> {
    const response = await this.dashboardService.fetchSessionsByDateRange(companyId, dateRange, page);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  private prepareLineRequestsDataset(dashboardResponse: DashboardApiResponse): DashboardChart {
    const dates = uniq(dashboardResponse.requestsStats.map((item: RequestsStats) => item.date));
    const groupedByRequest = groupBy(dashboardResponse.requestsStats, function (item: RequestsStats) {
      return item.request;
    });
    const datasets = map(groupedByRequest, (items: RequestsStats[], request: string) => {
      const mappedCounts = map(items, (item: RequestsStats) => {
        return item.count;
      });
      return {label: request, data: mappedCounts} as DatasetChart;
    });
    return {labels: dates, datasets: datasets};
  }

  private preparePieRequestsDataset(dashboardResponse: DashboardApiResponse): DashboardChart {
    const requests = uniq(dashboardResponse.requestsStats.map((item: RequestsStats) => item.request));
    const groupedByRequest = groupBy(dashboardResponse.requestsStats, function (item: RequestsStats) {
      return item.request;
    });

    const countByRequests: number[] = [];
    each(groupedByRequest, (values: RequestsStats[], request) => {
      countByRequests.push(sumBy(values, (item: RequestsStats) => item.count));
    })
    return {labels: requests, datasets: [{label: '', data: countByRequests}]};
  }

  private prepareGenderDataset(dashboardResponse: DashboardApiResponse): DashboardChart {
    return {
      labels: Object.keys(dashboardResponse.genderStats),
      datasets: [{label: '', data: Object.values(dashboardResponse.genderStats)}],
    }
  }
}
