import {inject, injectable} from "inversify";
import {RescheduleService} from "../domain";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {HttpService} from "@meclee/http";

@injectable()
export class ApiRescheduleService implements RescheduleService {

  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createRequest(sessionId: string, newTime: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.put<void>(`marketplace/reschedule-requests`, {
      session: sessionId,
      time: newTime,
    }, {});
  }

  async confirmRequest(requestId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/reschedule-requests/${requestId}/confirm`, {}, {});
  }

  async rejectRequest(requestId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/reschedule-requests/${requestId}/reject`, {}, {});
  }
}
