import {inject, injectable} from "inversify";
import {authTypes} from "../../../di/types";
import {AuthService} from "../domain";
import {LoginFlow} from "../../../models/login";
import {OauthProvider} from "../enums/oauthProvider";

@injectable()
export class CreateLoginFlowUseCase {
  constructor(
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async byEmailPassword(fingerprint: string): Promise<LoginFlow> {
    const loginFlow = await this.authService.createEmailPasswordLoginFlow(fingerprint);
    if (loginFlow.isOk()) {
      return loginFlow.value;
    } else {
      throw loginFlow.error;
    }
  }

  async byEmail(fingerprint: string, email: string): Promise<LoginFlow> {
    const loginFlow = await this.authService.createPasswordlessLoginFlow(fingerprint, email);
    if (loginFlow.isOk()) {
      return loginFlow.value;
    } else {
      throw loginFlow.error;
    }
  }

  async byOauth(fingerprint: string, provider: OauthProvider): Promise<string> {
    const loginFlow = await this.authService.createOAuthLoginFlow(fingerprint, provider);
    if (loginFlow.isOk()) {
      return loginFlow.value.metadata.authLink;
    } else {
      throw loginFlow.error;
    }
  }
}
