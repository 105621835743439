import {RuntimeError} from "@meclee/contracts";

export type LoginFlow = {
  flowId: string,
}

export class UserNotFoundError implements RuntimeError {
  getMessage(): TranslatableMessage {
    return {message: "auth.signInForm.errors.USER_NOT_FOUND"};
  }
}

export class AuthInternalError implements RuntimeError {
  getMessage(): TranslatableMessage {
    return {message: "auth.signInForm.errors.SERVER_IS_NOT_AVAILABLE"};
  }
}
