import {useStorage} from "@vueuse/core";
import {GetSessionUseCase} from "@meclee/auth/features/multiprofile";
import {authTypes} from "@meclee/auth/di/types";
import {Session} from "@meclee/auth/models/session";

const session: Ref<Session|undefined> = ref();
const isLoggedIn = useStorage('isLoggedIn', false);

export function useAuthSession() {
  const {$container} = useNuxtApp();
  const getSessionUseCase: GetSessionUseCase = $container.get<GetSessionUseCase>(authTypes.GetSessionUseCase);

  async function fetchSession() {
    try {
      session.value = await getSessionUseCase.execute();
      useBugsnag().setUser(session.value.userId, session.value.selectedProfile.id, session.value.selectedProfile.label);
      isLoggedIn.value = true;
    } catch (e) {
      isLoggedIn.value = false;
    }
  }

  async function resetSession() {
    isLoggedIn.value = false;
    session.value = undefined;
  }

  return {
    isLoggedIn,
    session,
    fetchSession,
    resetSession,
  }
}
