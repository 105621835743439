import {inject, injectable} from "inversify";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {FaqService} from "../domain";
import {FaqList} from "../../../models/faq";

@injectable()
export class ApiFaqService implements FaqService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getFaqListById(id: string): Promise<Result<FaqList, RuntimeError>> {
    return await this.httpService.get<FaqList>(`content/faqs/${id}`, {}, {});
  }
}
