<template>
  <NuxtLoadingIndicator color="linear-gradient(90deg, rgba(0,245,255,1) 0%, rgba(46,178,244,1) 65%)" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const i18nHead = useLocaleHead({
  addSeoAttributes: {}
});
const linkHead = i18nHead.value.link ?? [];
const metaHead = i18nHead.value.meta ?? [];

const route = useRoute();
useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs?.lang
  },
  link: linkHead,
  meta: [
    ...metaHead,
    {name: 'theme-color', content: '#2eb2f4'},
  ],
  title: 'Meclee',
  titleTemplate: '%s - Meclee',
  bodyAttrs: {
    class: computed(() => {
      return ['public', 'public-checkout', 'public-containered', 'matching', 'survey'].includes(route.meta.layout) ? 'meclee-ui' : '';
    }),
  },
})

onMounted(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      if (registrations.length) {
        for(let registration of registrations) {
          registration.unregister();
        }
      }
    });
  }
})
</script>
