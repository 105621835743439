import {injectable, inject} from "inversify";
import {cabinetEmployeeTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {DashboardService} from "../domain";
import {EmployeeDashboard} from "../../../models/dashboard";

@injectable()
export class GetDashboardUseCase {

  constructor(
    @inject(cabinetEmployeeTypes.DashboardService) private readonly dashboardService: DashboardService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getDashboard(employeeId: string): Promise<EmployeeDashboard> {
    const response = await this.dashboardService.getDashboardByEmployee(employeeId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

}
