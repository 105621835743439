import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {PaginatedSessionList, Session} from "../../../models/order";
import {SessionService} from "../domain";

@injectable()
export class GetSessionUseCase {

  constructor(
    @inject(marketplaceTypes.SessionService) private readonly sessionService: SessionService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAllSessionsByCustomerId(customerId: string, page: number): Promise<PaginatedSessionList> {
    const response = await this.sessionService.getSessionsByCustomerId(customerId, page);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getNearestSessionByProfileId(profileId: string): Promise<NearestSession> {
    const response = await this.sessionService.getNearestSessionByProfileId(profileId);
    if (response.isOk()) {
      return response.value;
    }

    if (response.isErr()) {
      throw response.error;
    }
  }

}
