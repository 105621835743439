import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import {PayoutRequestRepository} from "../ports";
import {PayoutRequest} from "../../../entities/payout";

@injectable()
export class ApiPayoutRequestRepository implements PayoutRequestRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async create(payoutMethodId: string, creditTransactionIds: string[]): Promise<Result<{file: Blob, name: string}|void, RuntimeError>> {
    return await this.httpService.put<void>(
      `finance/payout-requests`,
      {payoutMethodId, transactions: creditTransactionIds},
      {}
    );
  }

  async findAllByUserId(userId: string, date: string, type: string): Promise<Result<PayoutRequest[], RuntimeError>> {
    return await this.httpService.get<PayoutRequest[]>(
      `finance/payout-requests`,
      {userId, date, type},
      {}
    );
  }
}
