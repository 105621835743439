import {InvoiceService} from "../domain";
import {HttpService} from "@meclee/http";
import {httpTypes} from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {Invoice} from "../../../models/invoice";
import {RuntimeError} from "@meclee/contracts";
import {injectable, inject} from "inversify";

@injectable()
export class ApiInvoiceService implements InvoiceService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getOneById(id: string): Promise<Result<Invoice, RuntimeError>> {
    return await this.httpService.get<Invoice>(`finance/invoices/${id}`, {}, {});
  }
}
