import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import 'reflect-metadata';
import {MeetingService} from "../domain";
import {CallUpResponse, Meeting} from "../../../models/video";

@injectable()
export class ApiMeetingService implements MeetingService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getMeeting(meetingId: string): Promise<Result<Meeting, RuntimeError>> {
    return await this.httpService.get<Meeting>(`chat/meetings/${meetingId}`, {}, {});
  }

  async callUp(meetingId: string): Promise<Result<CallUpResponse, RuntimeError>> {
    return await this.httpService.post<CallUpResponse>(`chat/meetings/${meetingId}`, {}, {});
  }
}
