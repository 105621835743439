import { inject, injectable } from "inversify";
import { httpTypes } from "@meclee/http/di/types";
import { RuntimeError } from "@meclee/contracts";
import { HttpService } from "@meclee/http";
import { ok, Result } from "neverthrow";
import "reflect-metadata";
import {MyCustomersService} from "../domain";

@injectable()
export class ApiMyCustomersService implements MyCustomersService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService
  ) {}

  async getList(profileId: string, page: number): Promise<Result<any, RuntimeError>> {
    return await this.httpService.get<any[]>(`marketplace/psychologists/${profileId}/my-customers`, {page});
  }

  async getSessions(profileId: string, customerId: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.get<any[]>(`marketplace/psychologists/${profileId}/my-customers/${customerId}`);
  }
}
