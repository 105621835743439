import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import {RoomService} from "../domain";
import {Room} from "../../../models/message";
import {Participant} from "../../../models/video";

type RoomApiSchema = {
    id: string,
    name: string,
    participants: {id: string, name: string, photoUrl: string}[],
}

@injectable()
export class ApiRoomService implements RoomService {
    constructor(
        @inject(httpTypes.HttpService) private readonly httpService: HttpService,
    ) { }

    async fetchRoomsByProfileId(profileId: string): Promise<Result<Room[], RuntimeError>> {
        let body = {
            profile: profileId,
        };
        return await this.httpService.get<Room[]>(`chat/rooms`, body, {});
    }

    async fetchRoomById(profileId: string, roomId: string): Promise<Result<Room, RuntimeError>> {
        const response = await this.httpService.get<RoomApiSchema>(`chat/rooms/${roomId}`, {profile: profileId}, {});
        return response.map<Room>((typedResponse: RoomApiSchema) => {
            const participants: {[id: string]: Participant} = {};
            typedResponse.participants.forEach(item => {
                participants[item.id] = {id: item.id, name: item.name, photoUrl: item.photoUrl, isOnline: false};
            });
            return {id: typedResponse.id, name: typedResponse.name, participants};
        });
    }

    async setName(profileId: string, roomId: string, roomName: string): Promise<Result<void, RuntimeError>> {
      return await this.httpService.post<void>(`chat/rooms/${roomId}/name`, {
        name: roomName,
        profileId: profileId,
      }, {});
    }

    async getNearestSessionsInRoom(roomId: string): Promise<Result<any[], RuntimeError>> {
      return await this.httpService.get<any[]>(`chat/rooms/${roomId}/nearest-sessions`, {}, {});
    }

    async getLastSessionInRoom(roomId: string): Promise<Result<any, RuntimeError>> {
      return await this.httpService.get<any>(`chat/rooms/${roomId}/last-session`, {}, {});
    }

    async getParticipantsInRoom(roomId: string): Promise<Result<Participant[], RuntimeError>> {
      return await this.httpService.get<Participant[]>(`chat/rooms/${roomId}/participants`, {}, {});
    }

    async getRoomName(roomId: string): Promise<Result<{result: string}, RuntimeError>> {
      return await this.httpService.get<{result: string}>(`chat/rooms/${roomId}/name`, {}, {});
    }
}
