import {injectable, inject} from "inversify";
import {cabinetPsychologistTypes} from "../../../di/types";
import {AccountService, CardsService, FondyService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {Card} from "../../../models/card";

@injectable()
export class CardsUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.CardsService) private readonly cardsService: CardsService,
    @inject(cabinetPsychologistTypes.FondyService) private readonly fondyService: FondyService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createFondyCard(cardNumber: number, exp: string, cvv: number): Promise<void> {
    const fondyTokenResponse = await this.cardsService.getFondyToken();
    if (fondyTokenResponse.isOk()) {
      const fondyVerificationResponse = await this.fondyService.verifyCard(
        cardNumber, exp, cvv, fondyTokenResponse.value.token
      );

      if (fondyVerificationResponse.isOk()) {
        this.notifierService.createSuccessNotification('finance.cardVerification.successNotification');
      }

      if (fondyVerificationResponse.isErr()) {
        this.notifierService.notifyFromCustomError(fondyVerificationResponse.error);
        throw fondyVerificationResponse.error;
      }
    } else {
      this.notifierService.notifyFromHttpError(fondyTokenResponse.error);
      throw fondyTokenResponse.error;
    }
  }

  async createLiqpayCard(): Promise<string> {
    const liqpayResponse = await this.cardsService.createLiqpayLink();
    if (liqpayResponse.isOk()) {
      return liqpayResponse.value.url;
    } else {
      throw liqpayResponse.error;
    }
  }

  async createWayforpayCard(): Promise<string> {
    const wfpResponse = await this.cardsService.createWayforpayLink();
    if (wfpResponse.isOk()) {
      return wfpResponse.value.url;
    } else {
      throw wfpResponse.error;
    }
  }

  async getCardList(): Promise<Card[]> {
    const cardsResponse = await this.cardsService.getCards();
    if (cardsResponse.isOk()) {
      return cardsResponse.value;
    } else {
      this.notifierService.notifyFromHttpError(cardsResponse.error);
      throw cardsResponse.error;
    }
  }

  async deleteCardById(id: string) {
    const response = await this.cardsService.deleteCard(id);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async setCardDefault(id: string) {
    const response = await this.cardsService.setCardAsDefault(id);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
