import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {RegistrationService} from "../domain";
import {LoginFlow} from "../../../models/login";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class CreateRegistrationFlowUseCase {
  constructor(
    @inject(authTypes.RegistrationService) private readonly registrationService: RegistrationService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(fingerprint: string, locale: string, email: string, password: string, name: string): Promise<LoginFlow> {
    const registrationFlow = await this.registrationService.createRegisterFlow(fingerprint, locale, email, password, name);
    if (registrationFlow.isOk()) {
      return registrationFlow.value;
    } else {
      this.notifierService.notifyFromHttpError(registrationFlow.error);
      throw registrationFlow.error;
    }
  }
}
