import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {chatTypes} from "../../../di/types";
import {RoomService} from "../../text";
import {Participant} from "../../../models/participant";

@injectable()
export class ChatRoomSessionsUseCase {
  constructor(
    @inject(chatTypes.RoomService) private readonly roomService: RoomService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getNearestSessionsInRoom(roomId: string): Promise<any[]> {
    const response = await this.roomService.getNearestSessionsInRoom(roomId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getLastSessionInRoom(roomId: string): Promise<any> {
    const response = await this.roomService.getLastSessionInRoom(roomId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getParticipantsInRoom(roomId: string): Promise<Participant[]> {
    const response = await this.roomService.getParticipantsInRoom(roomId);
    if (response.isOk()) {
      return response.value;
    }
  }

  async getRoomName(roomId: string): Promise<string> {
    const response = await this.roomService.getRoomName(roomId);
    if (response.isOk()) {
      return response.value.result;
    }
  }
}
