import {injectable, inject} from "inversify";
import 'reflect-metadata';
import {FetchError, ofetch, FetchOptions} from "ofetch";
import {Err, err, ok, Result} from "neverthrow";
import {httpTypes} from "../di/types";
import {HttpService} from "../httpService";
import {ForbiddenError, NotFoundError, ServerError, UnauthorizedError, UnprocessableContentError} from "../errors";
import {RuntimeError} from "@meclee/contracts";
import {i18nTypes} from "@meclee/i18n/di/types";
import {I18nService} from "@meclee/i18n";
import {eventBusTypes} from "@meclee/eventbus/di/types";
import {EventBus} from "@meclee/eventbus";
import queryString from 'query-string';

@injectable()
export class OhMyFetchService implements HttpService {
  private refreshSessionAttempts = 0;

  constructor(
    @inject(httpTypes.ApiUrl) private readonly baseUrl: string,
    @inject(i18nTypes.I18nService) private readonly i18nService: I18nService,
    @inject(eventBusTypes.EventBus) private readonly eventBus: EventBus,
  ) { }

  private async createRequest<T>(endpoint: string, options: FetchOptions): Promise<Result<T, RuntimeError>> {
    try {
      options.headers['Accept'] = 'application/json';
      options.headers['Accept-Language'] = this.i18nService.getCurrentLocale();

      const fetcher = ofetch.create({ baseURL: `${this.baseUrl}/` });
      const response = await fetcher<T>.raw(endpoint, options);
      if (response._data instanceof Blob && response.headers.has('Content-disposition')) {
        const fileName = response.headers.get('Content-disposition').split('filename=')[1];
        return ok({file: response._data, name: fileName});
      } else if (response.status >= 300 && response.status < 400) {
        return err({
          code: response.status,
          url: response.headers.get('location'),
        });
      } else {
        return ok(response._data);
      }
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.statusCode === 401) {
          if (this.refreshSessionAttempts < 3) {
            try {
              this.refreshSessionAttempts++;
              await this.refreshSession();
            } catch (refreshError) {
              err(refreshError);
            }
            return this.createRequest<T>(endpoint, options);
          } else {
            this.eventBus.emit('refreshSessionFailed');
          }
        }
      }
      return this.mapError<T>(error);
    }
  }

  private async refreshSession() {
    await ofetch('/api/auth/refreshSession', {
      method: 'POST',
    });
  }

  async get<T>(endpoint: string, request: object = {}, headers: object = {}): Promise<Result<T, RuntimeError>> {
    let url = endpoint;
    if (Object.keys(request).length > 0) {
      url += '?'+queryString.stringify(request, {arrayFormat: 'bracket'});
    }

    return await this.createRequest<T>(url, {
      method: 'GET',
      retry: 5,
      headers: headers,
    });
  }

  async post<T>(endpoint: string, request: object = {}, headers: object = {}): Promise<Result<T, RuntimeError>> {
    return await this.createRequest<T>(endpoint, {
      method: 'POST',
      body: request,
      headers: headers,
    });
  }

  async put<T>(endpoint: string, request: object = {}, headers: object = {}): Promise<Result<T, RuntimeError>> {
    return await this.createRequest<T>(endpoint, {
      method: 'PUT',
      body: request,
      headers: headers,
    });
  }

  async delete<T>(endpoint: string, request: object = {}, headers: object = {}): Promise<Result<T, RuntimeError>> {
    return await this.createRequest<T>(endpoint, {
      method: 'DELETE',
      body: request,
      headers: headers,
    });
  }

  private mapError<T>(error: any): Err<T, RuntimeError> {
    if (error instanceof FetchError) {
      if (error.statusCode === 401) {
        return err(new UnauthorizedError());
      } else if (error.statusCode === 403) {
        return err(new ForbiddenError(error.data.variables ?? {}, error.data.message));
      } else if (error.statusCode === 404) {
        return err(new NotFoundError());
      } else if (error.statusCode === 422) {
        return err(new UnprocessableContentError(
          error.data.variables ? error.data.variables : error.data.message,
          error.data.variables ? error.data.message : undefined,
        ));
      } else {
        return err(new ServerError());
      }
    } else {
      console.error(error);
      return err(new ServerError());
    }
  }
}
