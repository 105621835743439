import {FondyService} from "../domain";
import {injectable} from "inversify";
import {ok, Result} from "neverthrow";

@injectable()
export class FakeFondyService implements FondyService {
  async verifyCard(cardNumber: number, exp: string, cvv: number, token: string): Promise<Result<any, string>> {
    ok(undefined);
  }

}
