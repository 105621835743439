import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetPsychologistTypes} from "../../../di/types";
import {ProfileRepository} from "../domain";
import {GeneralInfo, Service} from "../../../models/profile";

@injectable()
export class UpdateProfileUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.ProfileRepository) private readonly profileRepository: ProfileRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async updateGeneralInfo(profileId: string, generalInfo: GeneralInfo): Promise<void> {
    const response = await this.profileRepository.updateGeneralInfo(profileId, generalInfo);

    if (response.isOk()) {
      this.notifierService.createSuccessNotification('cabinet-psychologist.profile.update.toast.success', {});
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);

      throw response.error;
    }
  }

  async updateServices(profileId: string, services: Service[]): Promise<void> {
    const response = await this.profileRepository.updateServices(profileId, services);

    if (response.isOk()) {
      this.notifierService.createSuccessNotification('cabinet-psychologist.profile.update.toast.success', {});
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);

      throw response.error;
    }
  }
}
