import {AnyMongoAbility, defineAbility} from "@casl/ability";
import {Ability} from "../../../models/ability";

export class CaslAbilityFactory {
    public static createFromAbilities(abilities: Ability[]|undefined|null): AnyMongoAbility
    {
        if (abilities) {
            return defineAbility((allow) => {
                abilities.forEach((ability: Ability) => {
                    allow(
                        ability.action,
                        ability.subject,
                        !ability.fields.length ? undefined : ability.fields,
                        ability.conditions
                    );
                });
            });
        } else {
            return defineAbility((allow) => {
                allow('sign-in', 'Id/Auth');
            });
        }
    }
}
