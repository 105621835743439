import {inject, injectable} from "inversify";
import {DashboardService} from "../domain";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {HttpService} from "@meclee/http";
import {EmployeeDashboard} from "../../../models/dashboard";

@injectable()
export class ApiDashboardService implements DashboardService {

  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getDashboardByEmployee(profileId: string): Promise<Result<EmployeeDashboard, RuntimeError>> {
    return await this.httpService.get<EmployeeDashboard>(`profile/employees/${profileId}/dashboard`, {}, {});
  }
}
