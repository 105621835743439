import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutMethodRepository} from "../ports";

@injectable()
export class DeletePayoutMethod {
  constructor(
    @inject(financeTypes.PayoutMethodRepository) private readonly payoutMethodRepository: PayoutMethodRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(payoutMethodId: string): Promise<void> {
    const response = await this.payoutMethodRepository.deletePayoutMethod(payoutMethodId);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
