import {inject, injectable} from "inversify";
import {chatTypes} from "../../../di/types";
import {MessageService} from "../domain";
import {Message} from "../../../models/message";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class MessageUseCase {
  constructor(
    @inject(chatTypes.MessageService) private readonly messageService: MessageService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getMessagesByRoomId(roomId: string): Promise<Message[]> {
    const response = await this.messageService.fetchMessagesByRoomId(roomId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async sendTextMessage(roomId: string, text: string): Promise<Message> {
    const response = await this.messageService.sendTextMessage(roomId, text);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async markAsReadMessage(roomId: string, messageId: string): Promise<void> {
    const response = await this.messageService.readMessage(roomId, messageId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
