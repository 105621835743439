export const authTypes = {
  CreateLoginFlowUseCase: Symbol.for('AuthCreateLoginFlowUseCase'),
  LoginByEmailPasswordUseCase: Symbol.for('AuthLoginByEmailPasswordUseCase'),
  LoginByMagicTokenUseCase: Symbol.for('AuthLoginByMagicTokenUseCase'),
  LoginByOAuthCodeUseCase: Symbol.for('AuthLoginByOAuthCodeUseCase'),
  LogoutUseCase: Symbol.for('AuthLogoutUseCase'),
  ResetPasswordUseCase: Symbol.for('AuthResetPasswordUseCase'),
  GetSessionUseCase: Symbol.for('AuthGetSessionUseCase'),
  SwitchProfileUseCase: Symbol.for('AuthSwitchProfileUseCase'),
  CreateRegistrationFlowUseCase: Symbol.for('AuthCreateRegistrationFlowUseCase'),
  EmailConfirmationUseCase: Symbol.for('AuthEmailConfirmationUseCase'),
  EmployeeRegistrationUseCase: Symbol.for('AuthEmployeeRegistrationUseCase'),
  CustomerRegistrationUseCase: Symbol.for('AuthCustomerRegistrationUseCase'),
  AddCustomerProfileUseCase: Symbol.for('AuthAddCustomerProfileUseCase'),
  AddEmployeeProfileUseCase: Symbol.for('AuthAddEmployeeProfileUseCase'),
  IntegrationsUseCase: Symbol.for('AuthIntegrationsUseCase'),

  AuthService: Symbol.for('AuthService'),
  RegistrationService: Symbol.for('AuthRegistrationService'),
  EmployeeRegistrationService: Symbol.for('AuthEmployeeRegistrationService'),
  CustomerRegistrationService: Symbol.for('AuthCustomerRegistrationService'),
  SessionRepository: Symbol.for('AuthSessionRepository'),
  ApiSessionRepository: Symbol.for('AuthApiSessionRepository'),
  DexieSessionRepository: Symbol.for('AuthDexieSessionRepository'),
  SettingsService: Symbol.for('AuthSettingsService'),
}
