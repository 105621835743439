import {inject, injectable} from "inversify";
import {authTypes} from "../../../di/types";
import {AuthService} from "../domain";

@injectable()
export class ResetPasswordUseCase {
  constructor(
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async createRequest(fingerprint: string, email: string): Promise<void> {
    const response = await this.authService.createResetPasswordRequest(fingerprint, email);
    if (response.isErr()) {
      throw response.error;
    }
  }

  async confirmRequest(flowId: string, fingerprint: string, password: string): Promise<string> {
    const response = await this.authService.confirmResetPasswordRequest(flowId, fingerprint, password);
    if (response.isErr()) {
      throw response.error;
    }
  }
}
