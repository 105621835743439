import { inject, injectable } from "inversify";
import { httpTypes } from "@meclee/http/di/types";
import { RuntimeError } from "@meclee/contracts";
import { HttpService } from "@meclee/http";
import { ok, Result } from "neverthrow";
import "reflect-metadata";
import { EventType } from "../enums";
import { CalendarService } from "../domain";
import { Event } from "../../../models/schedule";

type EventApiResponse = {
  id: string;
  type: EventType;
  name: string;
  period: string | null;
  recurrenceRule: string | null;
  createdAt: string;
};

@injectable()
export class ApiCalendarService implements CalendarService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService
  ) {}

  async getEventsByPsychologistIdPerTime(profileId: string, time: { start: string; end: string }): Promise<Result<Event[], RuntimeError>> {
    return await this.httpService.get<EventApiResponse[]>(
      `marketplace/schedule/${profileId}/events`,
      time,
      {}
    );
  }

  async createEvent(
    profileId: string, date: string, duration: number, onlyRegularCustomers: boolean, timeZone: string, name: string|undefined
  ): Promise<Result<void, RuntimeError>> {
    return await this.httpService.put<void>(
      `marketplace/schedule/${profileId}/events`,
      {
        date,
        onlyRegularCustomers,
        name,
        duration,
        timeZone,
      },
      {}
    );
  }

  async editEvent(profileId: string, eventId: string, date: string, duration: number): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(
      `marketplace/schedule/${profileId}/events/${eventId}`,
      {
        date,
        duration,
        type: 'default',
      },
      {}
    );
  }

  async deleteEvent(profileId: string, eventId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.delete<void>(`marketplace/schedule/${profileId}/events/${eventId}`);
  }
}
