import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {contentTypes} from "../../../di/types";
import {BusinessFormService, FaqService} from "../domain";
import {B2BLeadForm} from "../../../models/forms";

@injectable()
export class SendBusinessFormUseCase {
  constructor(
    @inject(contentTypes.BusinessFormService) private readonly businessFormService: BusinessFormService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async send(formData: B2BLeadForm): Promise<void> {
    const response = await this.businessFormService.sendForm(formData);

    if (response.isOk()) {
      this.notifierService.createSuccessNotification('meclee-ui.businessConnect.success');
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
