import {injectable, inject} from "inversify";
import 'reflect-metadata';
import {I18nService} from "../i18nService";
import {i18nTypes} from "../di/types";

@injectable()
export class NuxtI18nService implements I18nService {

  constructor(
    @inject(i18nTypes.I18nCurrentLocale) private readonly currentLocale: string,
  ) { }

  translate(key: string, params?: any): string {
    if (process.client) {
      return useNuxtApp().$t(key, params);
    } else {
      return key;
    }
  }

  getCurrentLocale(): string {
    if (process.client) {
      return useNuxtApp().$i18n.locale.value ?? this.currentLocale;
    } else {
      return this.currentLocale;
    }
  }
}
