export const chatEvents = {
  Connected: Symbol.for('Connected'),
  Connecting: Symbol.for('Connecting'),
  Disconnected: Symbol.for('Disconnected'),

  ParticipantConnected: Symbol.for('ParticipantConnected'),
  ParticipantDisconnected: Symbol.for('ParticipantDisconnected'),

  RemoteTrackSubscribed: Symbol.for('RemoteTrackSubscribed'),
  RemoteTrackUnsubscribed: Symbol.for('RemoteTrackUnsubscribed'),

  LocalCameraEnabled: Symbol.for('LocalCameraEnabled'),
  LocalCameraDisabled: Symbol.for('LocalCameraDisabled'),
  LocalMicrophoneEnabled: Symbol.for('LocalMicrophoneEnabled'),
  LocalMicrophoneDisabled: Symbol.for('LocalMicrophoneDisabled'),
  LocalNetworkQualityChanged: Symbol.for('LocalNetworkQualityChanged'),
}
