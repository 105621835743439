import {AsyncContainerModule, interfaces} from "inversify";
import {authTypes} from "./types";
import {
  CreateLoginFlowUseCase,
  LoginByEmailPasswordUseCase,
  LoginByMagicTokenUseCase,
  LoginByOAuthCodeUseCase,
  LogoutUseCase, ResetPasswordUseCase
} from "../features/auth/application";
import {AuthService, NuxtAuthService} from "../features/auth";
import {
  DexieApiSessionRepository,
  SessionRepository,
  GetSessionUseCase,
  DexieSessionRepository,
  ApiSessionRepository,
  SwitchProfileUseCase,
  AddEmployeeProfileUseCase,
  AddCustomerProfileUseCase
} from "../features/multiprofile";
import {
  CreateRegistrationFlowUseCase,
  ApiRegistrationService,
  RegistrationService,
  EmailConfirmationUseCase,
  EmployeeRegistrationUseCase,
  EmployeeRegistrationService,
  ApiEmployeeRegistrationService,
  CustomerRegistrationUseCase,
  CustomerRegistrationService
} from "../features/registration";
import {ApiCustomerRegistrationService} from "../features/registration/infrastructure/apiCustomerRegistrationService";
import {IntegrationsUseCase} from "../features/settings/application";
import {SettingsService} from "../features/settings/domain";
import {ApiSettingsService} from "../features/settings/infrastructure";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<CreateLoginFlowUseCase>(authTypes.CreateLoginFlowUseCase).to(CreateLoginFlowUseCase);
  bind<LoginByEmailPasswordUseCase>(authTypes.LoginByEmailPasswordUseCase).to(LoginByEmailPasswordUseCase);
  bind<LoginByMagicTokenUseCase>(authTypes.LoginByMagicTokenUseCase).to(LoginByMagicTokenUseCase);
  bind<LoginByOAuthCodeUseCase>(authTypes.LoginByOAuthCodeUseCase).to(LoginByOAuthCodeUseCase);
  bind<LogoutUseCase>(authTypes.LogoutUseCase).to(LogoutUseCase);
  bind<GetSessionUseCase>(authTypes.GetSessionUseCase).to(GetSessionUseCase);
  bind<ResetPasswordUseCase>(authTypes.ResetPasswordUseCase).to(ResetPasswordUseCase);
  bind<SwitchProfileUseCase>(authTypes.SwitchProfileUseCase).to(SwitchProfileUseCase);

  bind<CreateRegistrationFlowUseCase>(authTypes.CreateRegistrationFlowUseCase).to(CreateRegistrationFlowUseCase);
  bind<EmailConfirmationUseCase>(authTypes.EmailConfirmationUseCase).to(EmailConfirmationUseCase);
  bind<EmployeeRegistrationUseCase>(authTypes.EmployeeRegistrationUseCase).to(EmployeeRegistrationUseCase);
  bind<CustomerRegistrationUseCase>(authTypes.CustomerRegistrationUseCase).to(CustomerRegistrationUseCase);
  bind<AddEmployeeProfileUseCase>(authTypes.AddEmployeeProfileUseCase).to(AddEmployeeProfileUseCase);
  bind<AddCustomerProfileUseCase>(authTypes.AddCustomerProfileUseCase).to(AddCustomerProfileUseCase);

  bind<IntegrationsUseCase>(authTypes.IntegrationsUseCase).to(IntegrationsUseCase);
  bind<SettingsService>(authTypes.SettingsService).to(ApiSettingsService);

  bind<AuthService>(authTypes.AuthService).to(NuxtAuthService);
  bind<RegistrationService>(authTypes.RegistrationService).to(ApiRegistrationService);
  bind<EmployeeRegistrationService>(authTypes.EmployeeRegistrationService).to(ApiEmployeeRegistrationService);
  bind<CustomerRegistrationService>(authTypes.CustomerRegistrationService).to(ApiCustomerRegistrationService);
  bind<SessionRepository>(authTypes.SessionRepository).to(ApiSessionRepository);
  bind<DexieSessionRepository>(authTypes.DexieSessionRepository).to(DexieSessionRepository);
  bind<ApiSessionRepository>(authTypes.ApiSessionRepository).to(ApiSessionRepository);
});
