import {inject, injectable} from "inversify";
import {RuntimeError} from "@meclee/contracts";
import {Room} from "../../../models/message";
import {Result} from "neverthrow";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {RoomService} from "../domain";
import {chatTypes} from "../../../di/types";

@injectable()
export class RoomUseCase {
  constructor(
    @inject(chatTypes.RoomService) private readonly roomService: RoomService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getRoomsByProfileId(profileId: string): Promise<Result<Room[], RuntimeError>> {
    const response = await this.roomService.fetchRoomsByProfileId(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getRoomById(profileId: string, roomId: string): Promise<Result<Room, RuntimeError>> {
    const response = await this.roomService.fetchRoomById(profileId, roomId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async setName(profileId: string, roomId: string, name: string): Promise<Result<void, RuntimeError>> {
    const response = await this.roomService.setName(profileId, roomId, name);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async getNearestSessions(roomId: string): Promise<Result<any[], RuntimeError>> {
    const response = await this.roomService.getNearestSessionsInRoom(roomId);
    if (response.isOk()) {
      return response.value;
    }
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
