import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {OrderService} from "../domain";

@injectable()
export class ConfirmOrderUseCase {

  constructor(
    @inject(marketplaceTypes.OrderService) private readonly orderService: OrderService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async confirm(orderId: string): Promise<void> {
    const response = await this.orderService.confirmOrder(orderId);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification('marketplace.order.orderSuccessfullyConfirmed', {orderId});
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);

      throw response.error;
    }
  }

}
