import {cabinetPsychologistTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {injectable, inject} from "inversify";
import {GoogleCalendarService} from "../domain";

@injectable()
export class GoogleCalendarsUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.GoogleCalendarService) private readonly googleCalendarService: GoogleCalendarService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getCalendarList(profileId: string): Promise<any[]> {
    const response = await this.googleCalendarService.getCalendarList(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async disconnect(profileId: string): Promise<any[]> {
    const response = await this.googleCalendarService.disconnect(profileId);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.googleCalendar.notification.disconnectSuccessful");
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async deleteCalendar(calendarId: string): Promise<any[]> {
    const response = await this.googleCalendarService.deleteCalendar(calendarId);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.googleCalendar.notification.calendarDeleteSuccessful");
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async syncCalendar(calendarId: string): Promise<any[]> {
    const response = await this.googleCalendarService.syncCalendar(calendarId);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.googleCalendar.notification.calendarSyncSuccessful");
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async addCalendar(profileId: string, name: string): Promise<any[]> {
    const response = await this.googleCalendarService.addCalendar(profileId, name);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.googleCalendar.notification.calendarAddSuccessful");
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
