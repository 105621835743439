import { Container } from "inversify";
import { seoTypes } from "@meclee/seo/di/types";
import { GetMetadataUseCase } from "@meclee/seo/features/metadata/application";

export default defineNuxtPlugin({
  name: 'seo',
  enforce: 'post',
  async setup(nuxtApp) {
    const { $container, $i18n }: { $container: Container } = nuxtApp;

    const getMetadataUseCase: GetMetadataUseCase = $container.get<GetMetadataUseCase>(seoTypes.GetMetadataUseCase);
    const metadata = await getMetadataUseCase.getAllMetadata();

    function getValueFromApi(key: string) {
      const currentPath = nuxtApp.$router.currentRoute.value.path;
      const locale = $i18n.locale.value;
      const neutralCurrentPath = currentPath.replace(`/${locale}`, '/').replace('//', '/');

      if (metadata[neutralCurrentPath]) {
        if (metadata[neutralCurrentPath][key] && metadata[neutralCurrentPath][key][locale]) {
          return metadata[neutralCurrentPath][key][locale];
        }
      }
    }

    return {
      provide: {
        seo: {
          getTitle(fallback: string, params: Record<string, string> = {}) {
            try {
              return getValueFromApi('title') ?? $i18n.t(fallback, params);
            } catch (e) {
              return $i18n.t(fallback, params);
            }
          },
          getDescription(fallback: string | undefined = undefined, params: Record<string, string> = {}) {
            try {
              if (fallback) {
                return getValueFromApi('description') ?? $i18n.t(fallback, params);
              } else {
                return getValueFromApi('description');
              }
            } catch (e) {
              return '';
            }
          },
          getSeoText() {
            try {
              return getValueFromApi('seoText');
            } catch (e) {
              return '';
            }
          },
        }
      }
    }
  }
})
