import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {RegistrationService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {InvalidVerificationCodeError} from "../../../models/registration";

@injectable()
export class EmailConfirmationUseCase {
  constructor(
    @inject(authTypes.RegistrationService) private readonly registrationService: RegistrationService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async confirm(flowId: string, code: string): Promise<void> {
    const response = await this.registrationService.confirmEmail(flowId, code);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(new InvalidVerificationCodeError());
      throw response.error;
    }
  }

  async confirmByRequestId(requestId: string, token: string): Promise<void> {
    const response = await this.registrationService.confirmEmailByRequestId(requestId, token);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(new InvalidVerificationCodeError());
      throw response.error;
    }
  }

  async resend(flowId: string): Promise<void> {
    const response = await this.registrationService.resendEmail(flowId);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
