import { inject, injectable } from "inversify";
import { httpTypes } from "@meclee/http/di/types";
import { RuntimeError } from "@meclee/contracts";
import { HttpService } from "@meclee/http";
import { ok, Result } from "neverthrow";
import "reflect-metadata";
import { GoogleCalendarService } from "../domain";

@injectable()
export class ApiGoogleCalendarService implements GoogleCalendarService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService
  ) {}

  async getCalendarList(profileId: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.get<any[]>(`id/profiles/${profileId}/google-calendars`, {});
  }

  async addCalendar(profileId: string, name: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.post<any[]>(`id/profiles/${profileId}/google-calendars`, {name});
  }

  async deleteCalendar(calendarId: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.delete<any[]>(`marketplace/google-calendars/${calendarId}`, {});
  }

  async syncCalendar(calendarId: string):  Promise<Result<any, RuntimeError>> {
    return await this.httpService.post<any[]>(`marketplace/google-calendars/${calendarId}/sync`, {});
  }

  async disconnect(profileIf: string):  Promise<Result<any, RuntimeError>> {
    return await this.httpService.delete<any[]>(`id/profiles/${profileIf}/google-calendars`, {});
  }
}
