import {inject, injectable} from "inversify";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {HttpService} from "@meclee/http";
import {MetadataService} from "../domain";

@injectable()
export class ApiMetadataService implements MetadataService {

  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getAllMetadata(): Promise<Result<any[], RuntimeError>> {
    return await this.httpService.get<any[]>(`seo/metadata`, {}, {});
  }
}
