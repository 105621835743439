import {injectable, inject} from "inversify";
import {SessionRepository} from "../domain";
import {authTypes} from "../../../di/types";
import {Session} from "../../../models/session";
import {AuthService} from "../../auth";

@injectable()
export class SwitchProfileUseCase {
  constructor(
    @inject(authTypes.SessionRepository) private readonly sessionRepository: SessionRepository,
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async execute(profileId: string): Promise<Session|undefined> {
    // @ts-ignore
    return await this.authService.switchProfile(profileId);
  }
}
