import {Container} from "inversify";
import {chatTypes} from "../../../../../di/types";
import {Message, Room} from "../../../../../models/message";
import {MessageUseCase, RoomUseCase} from "../../../application";
import {Participant} from "../../../../../models/participant";
import {MessageStatus} from "../../../../../enums";

let rooms = reactive<Room[]>([]);
const messages = ref<Message[]>([]);
const selectedRoomId = ref<string>();
const selectedRoomTab = ref<string>('active');
const selectedRoom = computed<Room>(() => {
    // @ts-ignore
    return rooms.find<Room>(room => room.id === selectedRoomId.value);
});

export function useTextChat() {
    // @ts-ignore
    const { $container }: {$container: Container} = useNuxtApp();
    const roomUseCase = $container.get<RoomUseCase>(chatTypes.RoomUseCase);
    const messageUseCase = $container.get<MessageUseCase>(chatTypes.MessageUseCase);

    async function fetchRooms(profileId: string) {
      try {
        Object.assign(rooms, await roomUseCase.getRoomsByProfileId(profileId));
      } catch (e) {
        throw e;
      }
    }

    async function updateRoom(newRoom: object) {
      rooms.forEach((room, i) => {
        if (room.id === newRoom.id) {
          rooms[i] = newRoom;
        }
      })
    }

    async function fetchMessages(roomId: string) {
      messages.value = await messageUseCase.getMessagesByRoomId(roomId);
    }

    async function sendTextMessage(message: string) {
      const result = await messageUseCase.sendTextMessage(selectedRoomId.value, message);
      addMessage(result);
    }

    async function setMessageStatus(roomId: string, messageId: string, isSelf: boolean, newStatus: MessageStatus) {
      const filteredMessages = messages.value.filter(item => item.id === messageId);
      if (filteredMessages) {
        const [message] = filteredMessages;

        if (message && !message.isRead && newStatus === MessageStatus.HasBeenRead) {
          if (!isSelf) {
            await messageUseCase.markAsReadMessage(roomId, message.id);
          }

          message.isRead = true;
        }
      }
    }

    function addMessage(message: Message) {
      const filteredMessages = messages.value.filter((item: Message) => item.id === message.id);
      if (filteredMessages.length === 0) {
        messages.value.push(message);
      }
    }

    function clearMessages () {
       messages.value.length = 0;
    }

    const {onlineProfiles} = useOnlineStatus();
    watchEffect(() => {
        rooms.forEach(room => {
          // @ts-ignore
          Object.values(room.participants).forEach((participant: Participant) => {
              // participant.isOnline = Object.values(onlineProfiles).flatMap((val) => val).includes(participant.id);
          });
        });
    });

    return {
        fetchRooms,
        fetchMessages,
        sendTextMessage,
        setMessageStatus,
        addMessage,
        clearMessages,
        updateRoom,

        selectedRoomId,
        selectedRoom,
        selectedRoomTab,
        rooms,
        messages,
    }
}
