import {inject, injectable} from "inversify";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {CategoriesService} from "../domain";
import {Category} from "../../../models/blog";

@injectable()
export class ApiCategoriesService implements CategoriesService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getAllCategories(): Promise<Result<Category[], RuntimeError>> {
    return await this.httpService.get<Category[]>(`content/blog/categories`, {}, {});
  }
}
