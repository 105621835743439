let onlineProfiles = reactive<{ [key: string]: string[] }>({});

export function useOnlineStatus() {

    function addProfiles(profileId: string, participants: string[]) {
      onlineProfiles[profileId] = participants;
    }

    function removeProfile(profileId: string) {
      delete onlineProfiles[profileId];
    }

    return {
        addProfiles,
        removeProfile,
        onlineProfiles,
    }
}
