import {injectable, inject} from "inversify";
import {cabinetPsychologistTypes} from "../../../di/types";
import {ProfileRepository} from "../../profile/domain";
import {PaymentMethodResponse} from "../../../models/profile";

@injectable()
export class GetPaymentMethodsUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.ProfileRepository) private readonly profileRepository: ProfileRepository,
  ) { }

  async getEnabled(profileId: string): Promise<PaymentMethodResponse[]> {
    const response = await this.profileRepository.getEnabledPaymentMethods(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
