import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts"
import {inject, injectable} from "inversify";
import {RequestsService} from "../contracts";

@injectable()
export class ApiRequestsService implements RequestsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getAllRequests(version: string): Promise<Result<{ id: string; categoryName: string; name: string }[], RuntimeError>> {
    return await this.httpService.get<{ id: string; categoryName: string; name: string }[]>(`utils/requests`, {version}, {});
  }
}
