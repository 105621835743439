import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {AuthService} from "../domain";

@injectable()
export class LoginByOAuthCodeUseCase {
  constructor(
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async execute(flowId: string, fingerprint: string, code: string): Promise<void> {
    const tokenPair = await this.authService.loginByOAuthCode(flowId, fingerprint, code);
    if (tokenPair.isOk()) {
      return tokenPair.value;
    } else {
      throw tokenPair.error;
    }
  }
}
