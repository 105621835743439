import {RuntimeError, TranslatableMessage} from "@meclee/contracts";

export class ClientError implements RuntimeError {
  public readonly statusCode: number = 400;

  constructor(
    private readonly variables: any[] = []
  ) { }

  getMessage(): TranslatableMessage {
    return {message: "http.errors.400", variables: this.variables};
  }
}

export class ForbiddenError implements RuntimeError {
  public readonly statusCode: number = 403;

  constructor(
    private readonly variables: Record<string, string>,
    private readonly errorMessage: string|undefined,
  ) { }

  getMessage(): TranslatableMessage {
    return {message: this.errorMessage ?? "http.errors.403", variables: this.variables};
  }
}

export class NotFoundError implements RuntimeError {
  public readonly statusCode: number = 404;

  constructor(
    private readonly variables: any[] = []
  ) { }

  getMessage(): TranslatableMessage {
    return {message: "http.errors.404", variables: this.variables};
  }
}

export class ServerError implements RuntimeError {
  public readonly statusCode: number = 500;

  constructor(
    private readonly variables: any[] = []
  ) { }

  getMessage(): TranslatableMessage {
    return {message: "http.errors.500", variables: this.variables};
  }
}

export class UnauthorizedError implements RuntimeError {
  public readonly statusCode: number = 401;

  constructor(
    private readonly variables: any[] = []
  ) { }

  getMessage(): TranslatableMessage {
    return {message: "http.errors.401", variables: this.variables};
  }
}

export class UnprocessableContentError implements RuntimeError {
  public readonly statusCode: number = 422;

  constructor(
    private readonly variables: Record<string, string>,
    private readonly errorMessage: string|undefined,
  ) { }

  getMessage(): TranslatableMessage {
    return {message: this.errorMessage ?? "http.errors.422", variables: this.variables};
  }
}
