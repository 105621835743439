// @ts-ignore
import Toast from "vue-toastification/dist/index.mjs";
import {useToast} from "vue-toastification";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Toast);

    const {error, success, info, warning} = useToast();

    return {
      provide: {
        showError: error,
        showWarning: warning,
        showSuccess: success,
        showInfo: info,
      }
    }
});
