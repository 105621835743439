import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {EmployeeRegistrationService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {ProfileRegistrationResponse} from "../../../models/registration";

@injectable()
export class EmployeeRegistrationUseCase {
  constructor(
    @inject(authTypes.EmployeeRegistrationService) private readonly employeeRegistrationService: EmployeeRegistrationService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async registerByEmailAndFlow(flowId: string, corporateEmail: string, problemDescription: string, requests: string[]): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.registerByEmailAndFlow(
      flowId, corporateEmail, problemDescription, requests
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async confirmEmail(flowId: string, profileId: string, code: string, fingerprint: string): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.confirmEmailByFlowId(
      flowId, profileId, code, fingerprint
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async resendCode(flowId: string, profileId: string): Promise<void> {
    const response = await this.employeeRegistrationService.resendEmailByFlowId(
      flowId, profileId
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async registerByInviteCodeAndFlow(fingerprint: string, flowId: string, inviteCode: string, problemDescription: string, requests: string[]): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.registerByInviteCodeAndFlow(
      fingerprint, flowId, inviteCode, problemDescription, requests
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
