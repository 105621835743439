import {authTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {injectable, inject} from "inversify";
import {SettingsService} from "../domain";
import {IntegrationSettings} from "../../../models/settings";

@injectable()
export class IntegrationsUseCase {
  constructor(
    @inject(authTypes.SettingsService) private readonly settingsService: SettingsService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getIntegrationSettings(profileId: string): Promise<IntegrationSettings> {
    const response = await this.settingsService.getIntegrationSettings(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async connectGoogle(profileId: string, fingerprint: string, withCalendar: boolean = false): Promise<string> {
    const response = await this.settingsService.connectGoogle(profileId, fingerprint, withCalendar);
    if (response.isOk()) {
      return response.value.authLink;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async disconnectGoogle(profileId: string, onlyCalendar: boolean = false): Promise<void> {
    const response = await this.settingsService.disconnectGoogle(profileId, onlyCalendar);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async connectFacebook(profileId: string, fingerprint: string): Promise<string> {
    const response = await this.settingsService.connectFacebook(profileId, fingerprint);
    if (response.isOk()) {
      return response.value.authLink;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async disconnectFacebook(profileId: string): Promise<void> {
    const response = await this.settingsService.disconnectFacebook(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async connectTelegram(profileId: string, fingerprint: string): Promise<string> {
    const response = await this.settingsService.connectTelegram(profileId, fingerprint);
    if (response.isOk()) {
      return response.value.authLink;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async disconnectTelegram(profileId: string): Promise<void> {
    const response = await this.settingsService.disconnectTelegram(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
