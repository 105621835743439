import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutRequest} from "../../../entities/payout";
import {PayoutRequestRepository} from "../ports";

@injectable()
export class GetPayoutRequests {
  constructor(
    @inject(financeTypes.PayoutRequestRepository) private readonly payoutRequestRepository: PayoutRequestRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAllByUserId(userId: string, date: string, type: string): Promise<PayoutRequest[]> {
    const response = await this.payoutRequestRepository.findAllByUserId(
      userId, date, type,
    );

    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
