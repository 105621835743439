import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {B2BCartService, PsychologistMessagesService} from "../domain";
import {Cart, Schedule} from "../../../models/cart";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {PsychologistMessage} from "../../../models/messages";

@injectable()
export class ApiPsychologistMessagesService implements PsychologistMessagesService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async sendForm(psychologistSlug: string, formData: PsychologistMessage): Promise<Result<{ roomId: string }, RuntimeError>> {
    return await this.httpService.post<{ roomId: string }>(`marketplace/psychologists/${psychologistSlug}/messages`, formData, {});
  }
}
