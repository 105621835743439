import {AsyncContainerModule, interfaces} from "inversify";
import {realtimeTypes} from "./types";
import {RealtimeService} from "../domain";
import {PusherJsService} from "../infrastructure/pusherJsService";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  const config = useRuntimeConfig();
  bind<string>(realtimeTypes.PusherAppKey).toConstantValue(config.public.pusherAppKey);
  bind<string>(realtimeTypes.PusherHost).toConstantValue(config.public.pusherHost);
  bind<string>(realtimeTypes.PusherMasterKey).toConstantValue(config.public.pusherMasterKey);
  bind<RealtimeService>(realtimeTypes.RealtimeService).to(PusherJsService);
});
