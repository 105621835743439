import {inject, injectable} from "inversify";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {PostsService} from "../domain";
import {PaginatedPostList, Post} from "../../../models/blog";

@injectable()
export class ApiPostsService implements PostsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getAllPosts(page:number, pageSize:number): Promise<Result<PaginatedPostList, RuntimeError>> {
    return await this.httpService.get<PaginatedPostList>(`content/blog/posts`, {page, limit: pageSize}, {});
  }

  async getPostsByCategory(categoryId: string, page: number, pageSize: number): Promise<Result<PaginatedPostList, RuntimeError>> {
    return await this.httpService.get<PaginatedPostList>(`content/blog/posts`, {category: categoryId, page, limit: pageSize}, {});
  }

  async getPostBySlug(categoryId: string, postSlug: string): Promise<Result<Post, RuntimeError>> {
    return await this.httpService.get<Post>(`content/blog/posts/${categoryId}/${postSlug}`, {}, {});
  }
}
