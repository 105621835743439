import {inject, injectable} from "inversify";
import {Money} from "@modules/core/money";
import {financeTypes} from "../../../di/types";
import {BalanceRepository} from "../ports";

@injectable()
export class GetBalances {
  constructor(
    @inject(financeTypes.BalanceRepository) private readonly balanceRepository: BalanceRepository,
  ) { }

  async execute(accountId: string): Promise<{[currency: string]: Money}> {
    const response = await this.balanceRepository.getBalances(accountId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
