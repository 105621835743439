import {injectable} from "inversify";
import {SessionRepository} from "../domain";
import {Session, Profile} from "../../../models/session";
// import Dexie from "dexie";

@injectable()
export class DexieSessionRepository implements SessionRepository {
  // private readonly dexie: Dexie;

  constructor() {
    // this.dexie = new Dexie("AuthDatabase");
    // this.dexie.version(1).stores({
    //   profiles: `id,selected,label,type,metadata,expires`,
    //   sessions: `id,accessExpires,refreshExpires`,
    //   abilities: `subject,action,fields,conditions`,
    // })
  }

  async getSession(): Promise<Session|undefined> {
    // const selectedProfile = await this.dexie.table('profiles')
    //   .where({'selected': 1})
    //   .filter((x: any) => x.expires.getTime() > (new Date()).getTime())
    //   .first();
    //
    // if (selectedProfile) {
    //   return {
    //     selectedProfile: selectedProfile,
    //     availableProfiles: await this.dexie.table('profiles').toArray(),
    //     abilities: await this.dexie.table('abilities').toArray(),
    //   } as Session;
    // }

    return undefined;
  }

  async persistSession(session: Session) {
    // await this.dexie.table('profiles').clear();
    // await this.dexie.table('abilities').clear();
    // await this.dexie.table('sessions').clear();
    //
    // const profiles = Object.values(session.availableProfiles).map((profile: any) => {
    //   profile.selected = session.selectedProfile.id === profile.id ? 1 : 0;
    //   profile.expires = new Date(Date.now() + 1000*60*5);
    //   return profile;
    // });
    // await this.dexie.table('profiles').bulkPut(profiles);
    //
    // this.dexie.table('abilities').bulkPut(Object.values(session.abilities));
  }
}
