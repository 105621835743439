import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutMethodRepository} from "../ports";
import {PayoutMethod} from "../../../entities/payout";

@injectable()
export class GetPayoutMethod {
  constructor(
    @inject(financeTypes.PayoutMethodRepository) private readonly payoutMethodRepository: PayoutMethodRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAll(): Promise<PayoutMethod[]> {
    const response = await this.payoutMethodRepository.getAllPayoutMethods();
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getOne(payoutMethodId: string): Promise<PayoutMethod> {
    const response = await this.payoutMethodRepository.getPayoutMethod(payoutMethodId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
