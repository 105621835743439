import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetPsychologistTypes} from "../../../di/types";
import {ProfileRepository} from "../domain";
import {GeneralInfo, Service} from "../../../models/profile";

@injectable()
export class GetProfileUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.ProfileRepository) private readonly profileRepository: ProfileRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getGeneralInfo(profileId: string): Promise<GeneralInfo> {
    const response = await this.profileRepository.getGeneralInfo(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getServices(profileId: string): Promise<Service[]> {
    const response = await this.profileRepository.getServices(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
