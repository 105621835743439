import {injectable, inject} from "inversify";
import {SessionRepository} from "../domain";
import {authTypes} from "../../../di/types";
import {Session} from "../../../models/session";
import {AuthService} from "../../auth";

@injectable()
export class GetSessionUseCase {
  constructor(
    @inject(authTypes.SessionRepository) private readonly sessionRepository: SessionRepository,
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async execute(): Promise<Session|undefined> {
    const session = await this.sessionRepository.getSession();
    if (session) {
      session.availableProfiles = Object.values(session.availableProfiles);
    }

    return session;
  }
}
