import {AsyncContainerModule, interfaces} from "inversify";
import {cabinetEmployeeTypes} from "./types";
import {DashboardService} from "../features/dashboard/domain";
import {ApiDashboardService} from "../features/dashboard/infrastructure";
import {GetDashboardUseCase} from "../features/dashboard/application";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<DashboardService>(cabinetEmployeeTypes.DashboardService).to(ApiDashboardService);

  bind<GetDashboardUseCase>(cabinetEmployeeTypes.GetDashboardUseCase).to(GetDashboardUseCase);
});
