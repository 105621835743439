import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {SessionService} from "../domain";

@injectable()
export class CancelSessionUseCase {

  constructor(
    @inject(marketplaceTypes.SessionService) private readonly sessionService: SessionService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async cancel(sessionId: string, withRefund: boolean = true): Promise<void> {
    const response = await this.sessionService.cancel(sessionId, withRefund);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification('marketplace.order.sessionSuccessfullyCanceled', {sessionId});
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);

      throw response.error;
    }
  }

}
