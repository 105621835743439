import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {MyPsychologistsService} from "../domain";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {CatalogItem} from "../../../models/catalog";

@injectable()
export class ApiMyPsychologistsService implements MyPsychologistsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getMyPsychologistsByProfileId(profileId:string): Promise<Result<CatalogItem[], RuntimeError>> {
    return await this.httpService.get<CatalogItem[]>(`id/profiles/${profileId}/my-psychologists`, {}, {});
  }
}
