import {inject, injectable} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {CatalogItem} from "../../../models/catalog";
import {MyPsychologistsService} from "../domain";

@injectable()
export class GetMyPsychologistsUseCase {
  constructor(
    @inject(marketplaceTypes.MyPsychologistsService) private readonly myPsychologistsService: MyPsychologistsService,
  ) { }

  async execute(profileId: string): Promise<CatalogItem[]> {
    const response = await this.myPsychologistsService.getMyPsychologistsByProfileId(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
