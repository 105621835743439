import {AccountService} from "../domain";
import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {BalanceResponse} from "../../../models/balance";

@injectable()
export class ApiAccountService implements AccountService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async topUpBalance(accountId: string, cardId: string, amount: number): Promise<Result<{paymentLink: string|undefined}, RuntimeError>> {
    return await this.httpService.post<{paymentLink: string}>(`finance/accounts/${accountId}/topUp`, {
      cardId, amount: amount, currency: 'UAH'
    }, {});
  }

  async getTransactions(accountId: string, year: number, month: number, timezone: string): Promise<Result<Transaction[], RuntimeError>> {
    return await this.httpService.get<Transaction[]>(`finance/accounts/${accountId}/transactions`, {
      year, month, timezone,
    }, {});
  }

  async getPsychologistBalance(date: string): Promise<Result<BalanceResponse[], RuntimeError>> {
    return await this.httpService.get<BalanceResponse[]>(`finance/balances/psychologist`, {date}, {});
  }
}
