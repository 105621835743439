import {inject, injectable} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {CartRepository} from "../domain";
import {Cart} from "../../../models/cart";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class CreateCartUseCase {
  constructor(
    @inject(marketplaceTypes.CartRepository) private readonly cartRepository: CartRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(serviceId: string): Promise<Cart> {
    const response = await this.cartRepository.createCart(serviceId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
