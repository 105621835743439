import ZoomVideo from '@zoom/videosdk';
import {inject, injectable} from "inversify";
import {MeetingState} from "../../../enums";
import {VideoTransportService} from "../domain";
import {eventBusTypes} from "@meclee/eventbus/di/types";
import {EventBus} from "@meclee/eventbus";
import {chatEvents} from "../../../index";
import {MeetingParticipant} from "../../../models/video";

@injectable()
export class ZoomVideoTransportService implements VideoTransportService {
  private _isCameraEnabled: boolean = false;
  private _isMicrophoneEnabled: boolean = true;
  private _isScreenShareEnabled: boolean = false;
  private _participants: { [key: string]: MeetingParticipant } = {};
  private _meetingState: MeetingState = MeetingState.Disconnected;
  private videoDeviceId: string | null = null;
  private audioDeviceId: string = 'default';

  private client: ZoomVideo;
  private session;

  constructor(
    @inject(eventBusTypes.EventBus) private readonly eventBus: EventBus,
  )  { }

  async connect(meetingId: string, accessToken: string, name: string, videoDeviceId: string | null, audioDeviceId: string): Promise<void> {
    this._meetingState = MeetingState.Connecting;
    this.eventBus.emit(chatEvents.Connecting, null);
    this.audioDeviceId = audioDeviceId;
    this.videoDeviceId = videoDeviceId;

    if (this.videoDeviceId) {
      this._isCameraEnabled = true;
    }

    this.client = ZoomVideo.createClient();
    await this.client.init('en-US', 'Global', {patchJsMedia: true})
    await this.client.join(meetingId, accessToken, name);

    this.session = this.client.getMediaStream();
    this._participants[this.client.getCurrentUserInfo().userId] = {
      name: name,
      isLocal: true,
      audioTrack: undefined,
      videoTrack: undefined,
      networkQuality: 5,
      isCameraEnabled: true,
      isMicrophoneEnabled: true,
      isScreenShareEnabled: false,
      isDominantSpeaker: false,
    };

    await this.startVideo();
    this.session.switchMicrophone(this.audioDeviceId);
    await this.session.startAudio();

    this.fetchAllUsers();

    this._meetingState = MeetingState.Connected;
    this.eventBus.emit(chatEvents.Connected, null);

    this.client.on('connection-change', (payload) => {
      if(payload.state === 'Closed') {
        this._meetingState = MeetingState.Disconnected;
        this.eventBus.emit(chatEvents.Disconnected, null);
      } else if(payload.state === 'Reconnecting') {
        this._meetingState = MeetingState.Connecting;
        this.eventBus.emit(chatEvents.Connecting, null);
      } else if(payload.state === 'Connected') {
        this._meetingState = MeetingState.Connected;
        this.eventBus.emit(chatEvents.Connected, null);
      } else if(payload.state === 'Fail') {
        this._meetingState = MeetingState.Disconnected;
        this.eventBus.emit(chatEvents.Disconnected, null);
      }
    })

    this.client.on('peer-video-state-change', (payload) => {
      if (payload.action === 'Start') {
        // a user turned on their video, render it
        this.session.renderVideo(document.querySelector('#participant-canvas'), payload.userId, 1920, 1080, 0, 0, 4);
      } else if (payload.action === 'Stop') {
        // a user turned off their video, stop rendering it
        this.session.stopRenderVideo(document.querySelector('#participant-canvas'), payload.userId)
      }
    })

    this.client.on('network-quality-change', (payload) => {
      this._participants[this.client.getCurrentUserInfo().userId].networkQuality = payload.level;
      this.eventBus.emit(chatEvents.LocalNetworkQualityChanged, null);
    })

    this.client.on('user-added', (payload) => {
      if (payload.length > 0) {
        this._participants[payload[0].userId] = {
          name: payload[0].displayName,
          isLocal: false,
          audioTrack: undefined,
          videoTrack: undefined,
          networkQuality: 5,
          isCameraEnabled: true,
          isMicrophoneEnabled: true,
          isScreenShareEnabled: false,
          isDominantSpeaker: false,
        };
        this.eventBus.emit(chatEvents.ParticipantConnected, null);
        // console.log(payload[0].userId + ' joined the session')
      }
    })

    this.client.on('user-updated', (payload) => {
      if (this._participants[payload[0].userId] === undefined) {
        this.fetchAllUsers();
      }
      if (payload[0].bVideoOn === false) {
        this._participants[payload[0].userId]['isCameraEnabled'] = false;
        this.eventBus.emit(chatEvents.RemoteTrackUnsubscribed, null);
      } else if (payload[0].bVideoOn === true) {
        this._participants[payload[0].userId]['isCameraEnabled'] = true;
        this.eventBus.emit(chatEvents.RemoteTrackSubscribed, null);
      }

      if (payload[0].muted === true) {
        this._participants[payload[0].userId]['isMicrophoneEnabled'] = false;
        this.eventBus.emit(chatEvents.RemoteTrackUnsubscribed, null);
      } else if( payload[0].muted === false) {
        this._participants[payload[0].userId]['isMicrophoneEnabled'] = true;
        this.eventBus.emit(chatEvents.RemoteTrackSubscribed, null);
      }
    })

    this.client.on('user-removed', (payload) => {
      if (payload.length > 0) {
        delete this._participants[payload[0].userId];
        this.eventBus.emit(chatEvents.ParticipantDisconnected, null);
      }
    })

    this.client.on('active-speaker', (payload) => {
      // new active speaker, for example, use for microphone visuals, css video border, etc.
    })

    return Promise.resolve(undefined);
  }

  disconnect(): Promise<void> {
    if (this._meetingState === MeetingState.Connected) {
      this.client.leave();
      this._meetingState = MeetingState.Disconnected;
      this._participants = {};
      this.eventBus.emit(chatEvents.Disconnected, null);
    }
    return Promise.resolve(undefined);
  }

  public get meetingState(): MeetingState {
    return this._meetingState;
  }

  public get participants(): MeetingParticipant[] {
    return Object.values(this._participants);
  }

  public get isCameraEnabled(): boolean {
    return this._isCameraEnabled;
  }

  public set isCameraEnabled(isCameraEnabled: boolean) {
    if (this.meetingState === MeetingState.Connected) {
      if (!isCameraEnabled) {
        this.session.stopVideo().then(() => {
          this.session.clearVideoCanvas(document.getElementById('self-view-canvas'));
          this._isCameraEnabled = isCameraEnabled;
          this.eventBus.emit(chatEvents.LocalCameraDisabled, null);
        });
      } else {
        this.startVideo().then(() => {
          this._isCameraEnabled = isCameraEnabled;
          this.eventBus.emit(chatEvents.LocalCameraEnabled, null);
        });

      }
    }
  }

  public get isMicrophoneEnabled(): boolean {
    return this._isMicrophoneEnabled;
  }

  public set isMicrophoneEnabled(isMicrophoneEnabled: boolean) {
    if (this.meetingState === MeetingState.Connected) {
      if (!isMicrophoneEnabled) {
        this.session.muteAudio().then(() => {
          this._isMicrophoneEnabled = isMicrophoneEnabled;
          this.eventBus.emit(chatEvents.LocalMicrophoneDisabled, null);
        });

      } else {
        this.session.unmuteAudio().then(() => {
          this._isMicrophoneEnabled = isMicrophoneEnabled;
          this.eventBus.emit(chatEvents.LocalMicrophoneEnabled, null);
        });
      }
    }
  }

  public get isScreenShareEnabled(): boolean {
    return this._isScreenShareEnabled;
  }

  public set isScreenShareEnabled(isScreenShareEnabled: boolean) {
    if (this.meetingState === MeetingState.Connected) {
      if (!isScreenShareEnabled) {

      } else {

      }
    }

    this._isScreenShareEnabled = isScreenShareEnabled;
  }

  private async startVideo() {
    const videoElement = document.getElementById('self-view-video');
    const videoCanvas = document.getElementById('self-view-canvas');
    if(this.session.isRenderSelfViewWithVideoElement()) {
      videoElement.style.display = "block";
      videoCanvas.style.display = "none";
      await this.session.startVideo({
        cameraId: this.videoDeviceId ?? 'default',
        videoElement: videoElement,
      });
    } else {
      await this.session.startVideo({
        cameraId: this.videoDeviceId ?? 'default',
      });
      videoElement.style.display = "none";
      videoCanvas.style.display = "block";
      await this.session.renderVideo(
        videoCanvas, this.client.getCurrentUserInfo().userId,
        1920, 1080, 0, 0, 3
      )
    }
  }

  private fetchAllUsers() {
    this.client.getAllUser().forEach((user) => {
      if (user.userId !== this.client.getCurrentUserInfo().userId) {
        this._participants[user.userId] = {
          name: user.displayName,
          isLocal: false,
          audioTrack: undefined,
          videoTrack: undefined,
          networkQuality: 5,
          isCameraEnabled: user.bVideoOn !== false,
          isMicrophoneEnabled: user.muted !== true,
          isScreenShareEnabled: false,
          isDominantSpeaker: false,
        };

        if (user.bVideoOn) {
          this.session.renderVideo(document.querySelector('#participant-canvas'), user.userId, 1920, 1080, 0, 0, 3)
        }
      }
    })
  }
}
