import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Money} from "@modules/core/money";
import {Result} from "neverthrow";
import {Transaction} from "../../../entities/balance";
import {BalanceRepository} from "../ports";

@injectable()
export class ApiBalanceRepository implements BalanceRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getBalances(accountId: string): Promise<Result<{[currency: string]: Money}, RuntimeError>> {
    return await this.httpService.get<{[currency: string]: Money}>(`finance/balances/${accountId}`, {}, {});
  }

  async getTransactions(accountId: string, date: string, type: 'b2c'|'b2b'|null, status: 'finished'|'unfinished'|null): Promise<Result<Transaction[], RuntimeError>> {
    return await this.httpService.get<Transaction[]>(`finance/balances/${accountId}/transactions`, {
      date,
      type,
      status,
    }, {});
  }
}
