import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {AuthService} from "../domain";

@injectable()
export class LoginByEmailPasswordUseCase {
  constructor(
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async execute(flowId: string, email: string, password: string): Promise<void> {
    const tokenPair = await this.authService.loginByEmailPassword(flowId, email, password);
    if (tokenPair.isOk()) {
      return tokenPair.value;
    } else {
      throw tokenPair.error;
    }
  }
}
