import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {PsychologistSuggestionService} from "../domain/psychologistSuggestionService";

@injectable()
export class ApiPsychologistSuggestionService implements PsychologistSuggestionService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async create(chatRoomId: string, timeZone: string, timeSlots: string[], serviceId: string|null = null): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>('marketplace/carts/suggests', {
      chatRoomId, timeZone, timeSlots, serviceId
    }, {});
  }
}
