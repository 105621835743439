import {ReviewsService} from "../domain";
import {injectable, inject} from "inversify";
import {HttpService} from "@meclee/http";
import {httpTypes} from '@meclee/http/di/types';
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {CustomerReview} from "../../../models/reviews";

@injectable()
export class ApiReviewsService implements ReviewsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async sendReview(sessionId: string, review: CustomerReview): Promise<Result<void, RuntimeError>> {
    return await this.httpService.put<void>(`marketplace/sessions/${sessionId}/reviews`, review, {});
  }

  async isSessionHasReview(sessionId: string): Promise<Result<{ok: boolean}, RuntimeError>> {
    return await this.httpService.get<any>(`marketplace/sessions/${sessionId}/reviews/check`, {}, {});
  }
}
