import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetPsychologistTypes} from "../../../di/types";
import {CalendarService} from "../domain";
import {EventFrequency} from "../enums/eventFrequency";
import {Frequency, RRule, datetime} from "rrule";
import {differenceInMinutes, parseISO} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

@injectable()
export class EventUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.CalendarService)
    private readonly calendarService: CalendarService,
    @inject(notificationsTypes.NotifierService)
    private readonly notifierService: NotifierService
  ) {}

  async createEvent(profileId: string, startAt: string, endAt: string, timeZone: string, repeat: EventFrequency, onlyRegularCustomers: boolean, name: string|undefined): Promise<void> {
    let date = startAt;
    if (repeat !== EventFrequency.NoRepeat) {
      const freqMapping = {
        [EventFrequency.EveryDay]: Frequency.DAILY,
        [EventFrequency.EveryWeek]: Frequency.WEEKLY,
      }
      const zonedTime = utcToZonedTime(startAt, timeZone);
      date = (new RRule({
        freq: freqMapping[repeat],
        dtstart: datetime(zonedTime.getFullYear(), zonedTime.getMonth()+1, zonedTime.getDate(), zonedTime.getHours(), zonedTime.getMinutes()),
        tzid: timeZone,
      })).toString();
    }

    const response = await this.calendarService.createEvent(
      profileId,
      date,
      differenceInMinutes(parseISO(endAt), parseISO(startAt)),
      onlyRegularCustomers,
      timeZone,
      name,
    );

    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.schedule.notification.createSuccess");
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async updateEvent(profileId: string, eventId: string, startAt: string, endAt: string) {
    const response = await this.calendarService.editEvent(
      profileId,
      eventId,
      startAt,
      differenceInMinutes(parseISO(endAt), parseISO(startAt)),
    );

    if (response.isOk()) {
      this.notifierService.createSuccessNotification("cabinet-psychologist.schedule.notification.editSuccess");
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async deleteEvent(profileId: string, eventId: string) {
    const response = await this.calendarService.deleteEvent(profileId, eventId);

    if (response.isOk()) {
      this.notifierService.createSuccessNotification(
        "cabinet-psychologist.schedule.notification.deleteSuccess",
        {}
      );
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
