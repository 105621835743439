import {required} from "./required";
import {email} from "./email";
import {password} from "./password";
import {name} from "./name";
import {emailVerificationCode} from "./emailVerificationCode";
import {numberGreater0} from "./numberGreater0";
import {numberGreaterThan} from "./numberGreaterThan";
import {numberWith0} from "./numberWith0";
import {discountPercent} from "./discountPercent";
import {timeSlotSize} from "./timeSlotSize";
import {notNull} from "./notNull";

type ValidationRule = {
  message: string,
  handler: Function,
}

export const rules: Record<string, ValidationRule> = {
  required: {
    message: 'required',
    handler: required,
  },
  email: {
    message: 'email',
    handler: email,
  },
  password: {
    message: 'password',
    handler: password,
  },
  name: {
    message: 'name',
    handler: name,
  },
  email_verification_code: {
    message: 'email_verification_code',
    handler: emailVerificationCode,
  },
  number_greater_0: {
    message: 'number_greater_0',
    handler: numberGreater0,
  },
  number_greater_than: {
    message: 'number_greater_than',
    handler: numberGreaterThan,
  },
  number_with_0: {
    message: 'number_with_0',
    handler: numberWith0,
  },
  discount_percent: {
    message: 'discount_percent',
    handler: discountPercent,
  },
  time_slot_size: {
    message: 'time_slot_size',
    handler: timeSlotSize,
  },
  not_null: {
    message: 'not_null',
    handler: notNull,
  },
}
