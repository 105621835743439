import { injectable, inject } from "inversify";
import { notificationsTypes } from "@meclee/notifications/di/types";
import { NotifierService } from "@meclee/notifications";
import { cabinetPsychologistTypes } from "../../../di/types";
import { CalendarService } from "../domain";
import { Event } from "../../../models/schedule";

@injectable()
export class GetScheduleUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.CalendarService)
    private readonly calendarService: CalendarService,
    @inject(notificationsTypes.NotifierService)
    private readonly notifierService: NotifierService
  ) {}

  async getEventsByProfileId(profileId: string, time: { start: string; end: string }): Promise<Event[]> {
    const eventsResponse = await this.calendarService.getEventsByPsychologistIdPerTime(profileId, time);
    if (eventsResponse.isOk()) {
      return eventsResponse.value;
    }
    if (eventsResponse.isErr()) {
      throw eventsResponse.error;
    }
  }
}
