import {enforce} from "vest";
import isAlphanumeric from 'validator/es/lib/isAlphanumeric';
import isUppercase from 'validator/es/lib/isUppercase';
import isLength from 'validator/es/lib/isLength';

enforce.extend({ isAlphanumeric });

export function emailVerificationCode(value: any) {
  enforce(value).message('validator.email_verification_code').condition(value => isAlphanumeric(value) && isUppercase(value) && isLength(value, {min: 6, max: 6}));
}
