import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {contentTypes} from "../../../di/types";
import {FaqService} from "../domain";
import {Post} from "../../../models/blog";
import {FaqList} from "../../../models/faq";

@injectable()
export class GetFaqListUseCase {
  constructor(
    @inject(contentTypes.FaqService) private readonly faqService: FaqService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getFaqListById(id: string): Promise<FaqList> {
    const response = await this.faqService.getFaqListById(id);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
