import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {MatchingService} from "../domain";

@injectable()
export class MatchingUseCase {
  constructor(
    @inject(marketplaceTypes.MatchingService) private readonly matchingService: MatchingService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createWorkflow(version: string, timeZone: string, fingerprint: string): Promise<any> {
    const response = await this.matchingService.createWorkflow(version, timeZone, fingerprint);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async commitWorkflow(workflowId: string, transition: string, data: any): Promise<any> {
    const response = await this.matchingService.commitWorkflow(workflowId, transition, data);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getCountPsychologists(workflowId: string, transition: string, data: any): Promise<number> {
    const response = await this.matchingService.getCountPsychologists(workflowId, transition, data);
    if (response.isOk()) {
      return response.value;
    } else {
      return 0;
    }
  }

  async getCategories(workflowId: string): Promise<any> {
    const response = await this.matchingService.getCategories(workflowId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }

  async getRequests(workflowId: string): Promise<any> {
    const response = await this.matchingService.getRequests(workflowId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
