import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {RescheduleService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class RescheduleUseCase {

  constructor(
    @inject(marketplaceTypes.RescheduleService) private readonly rescheduleService: RescheduleService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) {
  }

  async createRequest(sessionId: string, newTime: string): Promise<void> {
    const response = await this.rescheduleService.createRequest(sessionId, newTime);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async confirmRequest(requestId: string): Promise<void> {
    const response = await this.rescheduleService.confirmRequest(requestId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async rejectRequest(requestId: string): Promise<void> {
    const response = await this.rescheduleService.rejectRequest(requestId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
